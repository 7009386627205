import { useContext } from "react";
import { AppContext } from "../App";
import Course from "./Course";
import Label from "./Label";

function Menu() {
	const {
		setShop,
		setList,
		setMenuVisible,
		lockShop,
		lists,
		courses,
		settings,
		setCategory,
		shop,
		setCourse,
		setProducts,
	} = useContext(AppContext);

	return (
		<div id="menu">
			<div className="menu-header">
				<button onClick={() => setMenuVisible(false)}>
					<i className="fas fa-times"></i>
				</button>
			</div>
			<div className="scroll-content">
				<div className="menu-content">
					{!lockShop && (
						<button
							onClick={() => {
								setShop(false);
								setList(false);
								setMenuVisible(false);
							}}
						>
							<Label number={7} />
						</button>
					)}
					{shop && lists && lists.length > 0 && (
						<button
							onClick={() => {
								setList(false);
								setMenuVisible(false);
								setCourse(false);
								setCategory(false);
								setProducts([]);
							}}
						>
							<Label number={6} />
						</button>
					)}
				</div>
				{settings.menu.categories_menu_type === 1 && (
					<div className="menu-categories">
						<h5 className="page-title">Categorie</h5>
						{courses.map((course, i) => (
							<Course course={course} key={i} />
						))}
					</div>
				)}
				<div className="powered">
					<a href="https://www.sicilypos.com" target="_blank" rel="noreferrer">
						Powered by SicilyPos
					</a>
				</div>
			</div>
		</div>
	);
}

export default Menu;
