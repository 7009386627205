import React, { useEffect, useState } from "react";
import Api from "./components/api/api";
import Categories from "./components/Categories";
import Shops from "./components/Shops";
import Lists from "./components/Lists";
import Menu from "./components/Menu";
import Product from "./components/Product";
import Label from "./components/Label";
import Header from "./components/Header";

import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";
import "./css/header.css";
import "./css/menuCategories2.css";
import "./css/Home.css";
import "./css/Search.css";
import Search from "./components/Search";
import { Alert } from "bootstrap";

export const AppContext = React.createContext();
export const ShopContext = React.createContext();
export const ListContext = React.createContext();
export const ModeContext = React.createContext();

function App() {
	const [splash, setSplash] = useState(true);
	const [loading, setLoading] = useState(false);
	const [shop, setShop] = useState(false);
	const [brand, setBrand] = useState(false);
	const [list, setList] = useState(false);
	const [lists, setLists] = useState([]);
	const [settings, setSettings] = useState(false);
	const [mode, setMode] = useState("horizontal");
	const [shops, setShops] = useState([]);
	const [category, setCategory] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	const [product, setProduct] = useState(false);
	const [lockShop, setLockShop] = useState(false);
	const [lockList, setLockList] = useState(false);
	const [courses, setCourses] = useState([]);
	const [course, setCourse] = useState(false);
	const [products, setProducts] = useState([]);
	const [menu, setMenu] = useState([]);
	const [lang, setLang] = useState("it");
	const [labels, setLabels] = useState([]);
	const [slides, setSlides] = useState([]);
	const [searchVisible, setSearchVisible] = useState(false);

	useEffect(() => {
		init();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (settings) {
			applyColors();
			applyCss();
		}
	}, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (brand) applyBrandColors();
	}, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

	async function init() {
		await Api.oauth();
		await load_settings();
		await loadLabels();
		await check_params();
		await load_courses();
		await loadSlides();
		setSplash(false);
	}


  
  
	async function load_settings() {
    
    const response = await Api.post("/settings", { section: ["global", "menu"] });
		if (response.data) setSettings(response.data);
	}

	const loadLabels = async () => {
		console.log("loadLabels");
		const response = await Api.post("/labellist", {
			type: "menu",
		});
		setLabels(response.rows);
	};

	const loadSlides = async () => {
		console.log("loadSlides");
		const response = await Api.post("/slideslist", {
			orderby: "position",
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
				{
					name: "menu",
					value: 1,
					compare: "equal",
				},
			],
		});
		setSlides(response.rows);
	};

	async function applyColors() {
		document.documentElement.style.setProperty("--c1", settings.menu.c1);
		document.documentElement.style.setProperty("--c1c", settings.menu.c1c);
		document.documentElement.style.setProperty("--c1o", settings.menu.c1 + "50");
		document.documentElement.style.setProperty("--c2", settings.menu.c2);
		document.documentElement.style.setProperty("--c2c", settings.menu.c2c);

		document.title = "Digital Menu | " + settings.global.name;
		var link = document.querySelector("link[rel*='icon']") || document.createElement("link");
		link.type = "image/x-icon";
		link.rel = "shortcut icon";
		link.href = settings.menu.favicon;
		document.getElementsByTagName("head")[0].appendChild(link);
	}

	async function applyBrandColors() {
		document.documentElement.style.setProperty("--c1", brand.settings.menu.c1);
		document.documentElement.style.setProperty("--c1c", brand.settings.menu.c1c);
		document.documentElement.style.setProperty("--c2", brand.settings.menu.c2);
		document.documentElement.style.setProperty("--c2c", brand.settings.menu.c2c);
	}

	async function check_params() {
		const queryParams = new URLSearchParams(window.location.search);
		const shopID = queryParams.get("shop_id");
		const brandID = queryParams.get("brand_id");
		const listID = queryParams.get("list_id");
		console.log("shop_id", shopID);
		console.log("brand_id", brandID);
		console.log("list_id", listID);
		if (shopID) {
			const response_shop = await Api.post("/shops/get/" + shopID + "/");
			setShop(response_shop.data);
			setLockShop(true);
		}
		if (brandID) {
			const response_brand = await Api.post("/brands/get/" + brandID + "/");
			setBrand(response_brand.data);
		}
		if (listID) {
			const response_list = await Api.post("/lists/get/" + listID + "/");
			setList(response_list.data);
			setLockList(true);
		}
	}

	const load_courses = async () => {
		console.log("load_courses");
		setLoading(true);
		const response = await Api.post("/menucourses");
		setLoading(false);
		
		if (response.status === "Error") {
			alert(response.message);
		}
		setMenu(response.rows);
	};

	const applyCss = () => {
		console.log("applyCss");

		if (settings.menu) {
			var injectedStyles = document.createElement("style");
			injectedStyles.setAttribute("type", "text/css");
			injectedStyles.innerHTML = settings.menu.css;
			document.head.appendChild(injectedStyles);
		}
	};

	return (
		<AppContext.Provider
			value={{
				loading,
				setLoading,
				settings,
				setSettings,
				brand,
				setBrand,
				shop,
				setShop,
				shops,
				setShops,
				list,
				setList,
				mode,
				setMode,
				category,
				setCategory,
				menuVisible,
				setMenuVisible,
				product,
				setProduct,
				lockShop,
				setLockShop,
				lockList,
				setLockList,
				courses,
				setCourses,
				course,
				setCourse,
				products,
				setProducts,
				menu,
				lang,
				labels,
				lists,
				setLists,
				slides,
				setSearchVisible,
			}}
		>
			{!splash ? (
				<>
					<Header />
					{!shop && <Shops />}
					{shop && !list && <Lists />}
					{shop && list && <Categories />}
					{searchVisible && <Search />}
					{product && <Product />}
					{menuVisible && <Menu />}
					{loading && (
						<div className="loading">
							<div className="content">
								<i className="fas fa-circle-notch fa-spin"></i>
							</div>
						</div>
					)}
					<div className="footer">
						<div>
							{shop && list && (
								<div>
									{shop && (
										<>
											<i className="fas fa-map-marker-alt me-1"></i>
											{shop.name}
										</>
									)}
									{list && (
										<>
											<i className="fas fa-list me-1 ms-2"></i>
											{list !== "none" ? list.name : "Base"}
										</>
									)}
								</div>
							)}
							<div>
								<Label number={1} />
							</div>
							<a href="https://www.sicilypos.com/" target="_blank">
								Powered by SicilyPos
							</a>
						</div>
					</div>
				</>
			) : (
				<div className="lock">Loading...</div>
			)}
		</AppContext.Provider>
	);
}

export default App;

