import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import Products from "./Products";
import Label from "./Label";
//import { get_label } from "../functions/functions";

function Search() {
	//const { courses, setSearchVisible, lang, labels } = useContext(AppContext);
	const { courses, setSearchVisible } = useContext(AppContext);
	
	const [searchParam, setSearchParam] = useState("");
	const [filteredCourses, setFilteredCourses] = useState([]);

	useEffect(() => {
		search();
	}, [searchParam]); // eslint-disable-line react-hooks/exhaustive-deps

	const search = () => {
		if (!courses) return;
		if (!searchParam) setFilteredCourses([]);
		else {
			let res = [];
			for (const course of courses) {
				const cr = structuredClone(course);
				cr.categories = [];
				for (const category of course.categories) {
					const ct = structuredClone(category);
					ct.products = [];
					for (const product of category.products) {
						const pr = structuredClone(product);
						console.log(pr.name, product.name.search(new RegExp(searchParam, "i")));
						if (product.name.search(new RegExp(searchParam, "i")) > -1) {
							ct.products.push(pr);
						}
					}
					if (ct.products.length > 0) cr.categories.push(ct);
				}
				if (cr.categories.length > 0) res.push(cr);
			}
			console.log(res);
			setFilteredCourses(res);
		}
	};

	return (
		<div className="search">
			<div className="content">
				<div className="buttons-groups justify">
					<h3>
						<Label number={4} />
					</h3>
					<button onClick={() => setSearchVisible(false)}>
						<i className="fas fa-times"></i>
					</button>
				</div>
				<input
					type="text"
					className="form-control"
					placeholder="Scegli il testo da ricercare" //{get_label(5, lang, labels)}
					value={searchParam}
					onChange={(e) => setSearchParam(e.target.value)}
				/>
				<div className="scroll-content">
					{filteredCourses.map((course, i) => (
						<div key={i}>
							{course.categories.map((cat, n) => (
								<div key={n} id={"category-" + cat.id}>
									<Products products={cat.products} category={cat} />
								</div>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

export default Search;
