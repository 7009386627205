var hostname = window.location.hostname;
console.log("hostname", hostname);

var api_base_url = false;
if (hostname === "localhost") api_base_url = "http://localhost:8000/api";
else api_base_url = "https://api.sicilypos.com/api";
console.log("api_base_url", api_base_url);

let api_key = null;

async function oauth() {
	const response = await fetch(api_base_url + "/oauth", {
		method: "POST",
		headers: {
			"Shared-Key": "d2",
		},
		body: JSON.stringify({
			client_id: "yellgo_menu",
			client_secret: "123456",
			host: hostname,
		}),
	});

	const json = await response.json();

	api_key = json.token;
}

async function post(endpoint, request) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Shared-Key": api_key,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	if (json.success === 0) alert(json.error);

	return json;
}

export default { oauth, post };
