import { useContext } from "react";
import { AppContext } from "../App";
import Products from "./Products";

function ProductsOnePage() {
	const { courses } = useContext(AppContext);

	return (
		<>
			{courses &&
				courses.map((course, i) => (
					<div key={i}>
						{course.name && <h3 className="course-title">{course.name}</h3>}
						{course.categories.map((cat, n) => (
							<div key={n} id={"category-" + cat.id}>
								<Products products={cat.products} category={cat} />
							</div>
						))}
					</div>
				))}
		</>
	);
}

export default ProductsOnePage;
